import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 税务审核凭证列表
export const GetTaxResultPage = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/TaxForDriver/Page`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 审核税务凭证
export const auditTaxResult = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/TaxForDriver/Verify`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
