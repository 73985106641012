<template>
  <div class="audit-tax-real">
    <div class="facilityBox">
      <!-- 状态栏 -->
      <el-tabs
        v-model="activeCmpt"
        @tab-click="changeTabs"
      >
        <el-tab-pane
          :label="item.statusName"
          :name="item.status"
          v-for="(item, index) in statusList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>

      <!-- 检索栏 -->
      <el-form
        class="manageForm"
        :model="queryForm"
        :inline="true"
        label-width="140px"
      >
        <el-form-item
          label="司机姓名："
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.name"
            placeholder="输入司机姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号："
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.phone"
            placeholder="输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="身份证号"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.idCard"
            placeholder="输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="提交时间"
          label-width="96px"
        >
          <el-date-picker
            v-model="queryForm.SearchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd"
            style="width: 300px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-delete"
            @click="resetForm"
          >清空</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Name"
          label="司机姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Phone"
          label="司机手机号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="IdCard"
          label="司机身份证号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Status"
          label="税务实名凭证"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              class="preview-img"
              :src="scope.row.TaxUrl"
              :preview-src-list="[scope.row.TaxUrl]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreateTime"
          label="提交时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="VerifyRemark"
          label="驳回原因"
          fixed="right"
          width="200"
          show-overflow-tooltip
          v-if="activeCmpt == 'Reject'"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
          width="150"
          v-if="activeCmpt == 'Default'"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toAuditTaxResult(1,scope.row)"
            >同意</el-button>
            <el-button
              type="text"
              @click="toAuditTaxResult(2, scope.row)"
            >
              <span style="color: red;">拒绝</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.pageIndex"
          :page-size="pagination.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetTaxResultPage, auditTaxResult } from '@/api/auditInfo/auditTaxReal/index.js'

export default {
  data() {
    return {
      loading: false, //表格loading
      activeCmpt: "Default",
      statusList: [
        {
          statusName: "审核中",
          status: "Default",
        },
        {
          statusName: "审核失败",
          status: "Reject",
        },
        {
          statusName: "审核成功",
          status: "Agree",
        }
      ],
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      queryForm: {},
      tableData: []
    }
  },
  created() {
    this.getPage()
  },
  methods: {
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getPage()
    },

    // 搜索
    search() {
      this.pagination.page = 1;
      this.getPage()
    },

    // 清空搜索条件
    resetForm() {
      this.queryForm = {}
      this.getPage()
    },

    changeTabs(val) {
      this.activeCmpt = val.name
      this.search()
    },

    async getPage() {
      try {
        this.loading = true
        let params = {
          ...this.queryForm,
          status: this.activeCmpt,
          pageSize: this.pagination.pagesize,
          pageIndex: this.pagination.page
        }

        if (this.queryForm.SearchDate && this.queryForm.SearchDate.length == 2) {
          params.createTimeStart = this.queryForm.SearchDate[0] + ' 00:00:00'
          params.createTimeEnd = this.queryForm.SearchDate[1] + ' 23:59:59'
        }

        let { data = {} } = await GetTaxResultPage(params)
        this.tableData = data.DataList
      } finally {
        this.loading = false
      }
    },

    toAuditTaxResult(type, record) {
      this[type == 1 ? '$confirm' : '$prompt'](`是否${type == 1 ? '同意' : '拒绝'}该用户的税务实名？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value) return false;
          return value.length <= 200;
        },
        inputPlaceholder: '请输入拒绝原因',
        inputErrorMessage: '请输入200字符以内的拒绝原因！',
        type: 'warning'
      }).then(async ({ value }) => {
        await auditTaxResult({
          idCard: record.IdCard,
          status: type == 1 ? 'Agree' : 'Reject',
          verifyRemark: value
        })

        this.$message.success('操作成功')
        this.getPage()

      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-img {
  width: 50px;
  height: 50px;
}
</style>